.Container {
  position: relative;
  overflow: hidden;
}

.Video {
  width: 100%;
  height: 100%;
}

.Content {
  position: absolute;
  top: 0;
  color:#f1f1f1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.SubContent {
    text-align: center;
    padding: 10px;
}