body {
  background-color: #D6D8D9;
  background-repeat: no-repeat;
  background-size: auto;
  margin: 0;
  padding: 0;
  background-position: top;
}

.contactContainer { 
  height: 100vh;
}

.profileImg {
  margin: 0 auto;
  width: 25%;
  height: auto;
  padding: 1%;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}

.aboutMe {
  text-align: center;
  width: 75%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.aboutHeader {
  margin-top: 100px;
  margin-bottom: 50px;
  font-style: italic;
}

.abouMeText {
  padding: 10px;
}

.skillsBox {
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin-top: 100px;
  justify-content: center;
  text-align: center;
  padding: 2%;
}

.skillsText {
  padding: 2%;
  text-align: center
}

.skillsList {
  list-style-type: none;
}

.contactCard {
  margin-top: 10%;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.portfolioMain {
  margin-top: 5%;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  margin-bottom: 10%;
}

.logo {
  padding-bottom: 10px;
  max-height: 100px;
}

#linkedinIcon, #githubIcon {
  color: #eeeeee;
  padding: 5px;
}

.contactHeader, .card-body {
  color: #eeeeee;
}

.portLink, .portCard {
  color: #495057;
}

.projectName {
  font-weight: bold;
}

.portText {
  color: #495057;
}

.repoLink {
  margin-bottom: 2%;
}

@media screen and (max-width: 766px) {
  .contactContainer {
    padding-right: 25px;
    padding-left: 25px;
  }

  .contactMe {
    margin: 0 auto;
  }

  .portfolioMain {
    margin-bottom: 35%;
  }
}

@media screen and (max-width: 450px) {    
  .abouMeText, .skillsText {
      font-size: 14px;
      margin-right: 3%;
      margin-left: 3%
  }

  .skillsList {
    font-size: 12px;
    justify-items: center;
  }

  #aboutSec {
    margin-bottom: 25%;
    margin-left: 1%;
  }
}